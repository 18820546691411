import Vue from 'vue'
import { preloadImgs } from '@shein/common-function'

let instance
function mountComponent() {
  return new Promise(resolve => {
    import(/* webpackChunkName: "cart_share_drawer" */'./container.vue').then(module => {
      const component = module.default
      const Constructor = Vue.extend(component)
      instance = new Constructor()
      document.body.appendChild(instance.$mount().$el)
      resolve()
    })
  })
}

let hasPreloadSocialImgs = false

export function preloadCartShareImgs() {
  if (hasPreloadSocialImgs) return
  preloadImgs({
    imgs: [
      'https://img.ltwebstatic.com/images3_ccc/2024/11/01/10/1730454357f18c70f99bcba4d21abb16d24418182d.png',
      'https://img.ltwebstatic.com/images3_ccc/2024/11/01/e5/173045439821c16b13895cb19908e2caab55309e8c.png',
      'https://img.ltwebstatic.com/images3_ccc/2024/11/01/9a/17304544234c106f311ed1bf99fc729a9b21a0ed4c.png',
      'https://img.ltwebstatic.com/images3_ccc/2024/11/01/de/17304544547215860caa7daddeb1b3429f3d105601.png',
    ]
  }).then(() => {
    hasPreloadSocialImgs = true
  })
}

export async function openCartShareDrawer(options) {
  if (!instance) {
    await mountComponent()
  }
  instance.open(options)
}
