
import { throttle } from '@shein/common-function'

// 动画函数
function animate(element, initialStyles, targetStyles, duration) {
  const startTime = performance.now()
  const animateFrame = (currentTime) => {
    const elapsed = currentTime - startTime
    const progress = Math.min(elapsed / duration, 1) // 计算进度

    // 线性插值
    element.style.opacity = initialStyles.opacity + (targetStyles.opacity - initialStyles.opacity) * progress
    element.style.height = initialStyles.height + (targetStyles.height - initialStyles.height) * progress + 'px'
    if (progress < 1) {
      requestAnimationFrame(animateFrame)
    } else {
      if(typeof targetStyles.border !== 'undefined') {
        element.style.border = targetStyles.border
      }
    }
  }

  requestAnimationFrame(animateFrame)
}

export const  animateStart = (configs, duration = 300) => {
  configs.forEach(item => {
    const initialStyles = {
      opacity: parseFloat(getComputedStyle(item.attr).opacity),
      height: parseFloat(getComputedStyle(item.attr).height)
    }
    animate(item.attr, initialStyles, item.style, duration)
  })
}

export const initRealTimeScrollFn = (headerElDom, selfEl, cb = () => {}) => {
  if (!headerElDom) return
  const headerElHeight = headerElDom?.getBoundingClientRect()?.height || 0
  let lastScrollPosition = 0
  let lastDirection = 'up'
  return throttle({
    func: (e) => {
      const currentScrollPosition = e?.target?.scrollTop || document.documentElement.scrollTop
      const scrollDirection = currentScrollPosition > lastScrollPosition ? 'down' : 'up'
      if (scrollDirection === 'down' && lastDirection !== 'down') {
        animateStart([
          
          { attr: selfEl, style: { opacity: 1, height: headerElHeight } },
          { attr: headerElDom, style: { opacity: 0, height: 0, border: 'none' } },
        ], 100)
        cb && cb(true)
      }
      if (scrollDirection === 'up' && lastDirection !== 'up') {
        animateStart([
          { attr: headerElDom, style: { opacity: 1, height: headerElHeight, border: '' } },
          { attr: selfEl, style: { opacity: 0, height: 0 } },
        ], 100)
        cb && cb(false)
      }
      lastScrollPosition = currentScrollPosition
      lastDirection = scrollDirection
    },
    wait: 200,
  })
}
