import { getCurrentInstance } from 'vue'

let rootStore = null
let cartV2Store = null
export function useStore(type = 'cart') {
  const vm = getCurrentInstance()
  if (!vm) throw new Error('must be called in setup')
  if(type == 'root') {
    if(rootStore) return rootStore
    return rootStore = vm.proxy.$root.$store
  } else {
    if(cartV2Store) return cartV2Store
    const handler = {
      get: function (obj, prop) {
        return prop in obj ? obj[prop] : null
      },
    }
    return cartV2Store = {
      rootCartInfo: vm.proxy.$store.state.cartInfo,
      state: new Proxy(vm.proxy.$store.state.cart_v2, handler),
      getters: new Proxy(vm.proxy.$store.getters, handler),
      commit: vm.proxy.$store.commit,
      dispatch: vm.proxy.$store.dispatch
    }
  }
}
